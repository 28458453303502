import React, { useEffect, useState } from 'react'
import { API_ROOT } from 'config'
import useWebSocket from 'react-use-websocket'
import { buildWebsocketURL } from 'utils'
import NewNotification from './NewNotificationModal'

const NotificationsWebSocket = ({ token, toggle }) => {
    const socketUrl = buildWebsocketURL(API_ROOT, token, { notifications: true })
    const [lastMessage, setLastMessage] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [notificationId, setNotificationId] = useState(null)

    const styles = {
        messageContainer: {
            backgroundColor: '#670D95',
            border: '2px solid #FFF',
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            marginLeft: '-10px',
            marginTop: '14px',
            cursor: 'pointer',
        },
    }

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    useEffect(() => {
        if (isModalOpen) {
            setTimeout(() => {
                setIsModalOpen(false)
            }, 8000)
        }
    }, [isModalOpen])

    useWebSocket(socketUrl, {
        onOpen: () => {
            setLastMessage(null)
        },
        onMessage: (event) => {
            if (event && event.data) {
                setLastMessage(event.data)
                if (event.data && event.data !== 'New notification' && event.data !== ' ') {
                    try {
                        const parsedData = JSON.parse(event.data)
                        const { text: Text, id: Id } = parsedData;
                        setIsModalOpen(true)
                        setLastMessage(Text)
                        setNotificationId(Id)
                    } catch (err) {
                        console.error('Error parsing WebSocket message:', event.data, err)
                    }

                }

            }
        },
        onError: (event) => {
            console.error('WebSocket error:', event)
        },
        shouldReconnect: (closeEvent) => true, // Auto-reconnect on connection loss
    })

    if (!JSON.parse(localStorage.getItem('real_time_notification'))) return

    return (
        <>
            {lastMessage && lastMessage !== ' ' && (
                <div style={styles.messageContainer} onClick={toggle} />
            )}
            <NewNotification
                modal={isModalOpen}
                toggle={toggleModal}
                text={lastMessage}
                id={notificationId}
            />
        </>
    )
}

export default NotificationsWebSocket